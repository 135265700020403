.rangepicker :global .DateInput_input {
  font-size: 14px;
  color: var(--main-text-color);
  font-weight: 400;
}

.rangepicker :global .DateInput_input::placeholder {
  color: var(--info-text-color);
}

:global .DateRangePicker_picker {
  z-index: 5;
}

.rangepicker :global .DateInput_fang {
  z-index: 6;
}

.rangepicker :global .DateRangePicker {
  width: 100%;
}

.rangepicker :global .DateRangePickerInput {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rangepicker :global .CalendarMonth_table {
  border-collapse: separate;
  border-spacing: 0 2px;
} 

.rangepicker :global .CalendarMonth_caption {
  margin: 0 0 0 58px;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.15px;
  text-align: center;
  padding-top: 27px;
  padding-bottom: 37px;
}

.rangepicker :global .DayPicker_weekHeader_li {
  color: var(--rangepicker-weekday-caption);
}

.labelContainer {
  width: 100%;
  justify-content: space-between;
  display: flex;
}

.labelContainer div {
  width: 130px;
}

.rangepicker {
  margin-bottom: 8px;
}

.navPrev, 
.navNext {
  position: absolute;
  font-size: 12px;
  top: 32px;
}

.navPrev {
  left: 32px;
}

.navNext {
  right: 32px;
}