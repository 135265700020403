.dayCell {
  width: 39px;
  height: 38px;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
}

.dayCellValid:hover {
  color: var(--main-text-color);
  background: var(--rangepicker-cell-span);
}

.dayCellMinStayRestricted {
  color: var(--rangepicker-cell-disabled-text);
  background: #fff;
}

.dayCellBlocked {
  color: var(--rangepicker-cell-disabled-text);
  background: var(--rangepicker-cell-disabled);
}

.dayCellClosedToArrival{
  text-decoration: line-through;
}

.dayCellMinNightsRestricted,
.onDatesChange.dayCellMinNightsRestricted {
  color: var(--rangepicker-cell-disabled-text);
  background: #fff;
}

.dayCellBlockedOutOfRange {
  background: #fff;
  opacity: 0.5;
}

.dayCellHoveredSpan,
.dayCellHoveredSpan:hover {
  background: #b2f1ec;
  color: #007a87;
}

.dayCellSelectedStart {
  border-radius: 2px 0 0 2px;
}

.dayCellSelectedEnd {
  border-radius: 0 2px 2px 0;
}

.dayCellSelectedStart,
.dayCellSelectedStart:hover,
.dayCellSelectedStart:active,
.dayCellSelectedEnd,
.dayCellSelectedEnd:hover,
.dayCellSelectedEnd:active {
  color: var(--rangepicker-cell-end-text);
  background: var(--rangepicker-cell-end);
  text-decoration: none;
}

.dayCellSelectedSpan {
  color: var(--main-text-color);
  background: var(--rangepicker-cell-span);
}

.dayCellSelectedSpan:hover,
.dayCellSelectedSpan:active {
  color: var(--main-text-color);
  background: var(--rangepicker-cell-span);
}