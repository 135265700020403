.hotelDescription {
  margin: 0;
  font-size: 14px;
  white-space: pre-wrap;
  font-family: inherit;
  color: var(--main-text-color);
}

.hotelTitleSection {
  padding-bottom: 1.5rem;
}