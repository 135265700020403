.wrapper {
  display: flex;
  min-width: 1080px;
}

.left {
  width: 100%;
  max-width: 1400px;
}

.right {
  width: 100%;
  position: relative;
}