.occupancyContainer {
  display: flex;
  align-items: flex-end;
  line-height: 14px;
}

.occupancyCaption {

}

.iconActive {
  color: var(--icon-color);
}

.iconInactive {
  color: var(--info-text-color);
}