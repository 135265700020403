.title {
  cursor: pointer;
  color: #008ecd;
  text-decoration: none;
  padding: 0;
  border: none;
  line-height: 20px;
  font-size: 14px;
  text-align: left;
}

.title:global.btn.btn-link:active,
.title:global.btn.btn-link:hover,
.title:global.btn.btn-link:focus {
  outline: none;
  box-shadow: none;
  color: #008ecd;
}