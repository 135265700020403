.wrapper {
  flex: 1;
  background: #f9fbfd;
  height: calc(100vh - 56px);
}

.list {
  padding: 20px;
  overflow: auto;
  max-height: 100%;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
}

.listGrid {
  display: grid;
}

.item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  background: #ffffff;
  box-shadow: 0 5px 20px 0 rgba(231, 238, 245, 0.77);
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  align-self: center;
  flex: 1;
  min-height: 458px;

}

.item:hover .overlay,
.itemHighlighted .overlay {
  display: block;
}


.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  display: none;
  background-color: #0d70cd93;
  transition: all 200ms ease;
  pointer-events: none;
  z-index: 1;
}

.previewBtnWrapper {
  height: 220px;
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imageWrapper {
  border-bottom: 1px solid #f1f8fe;
  height: 220px;
  position: relative;
  box-sizing: border-box;
}

.previewButton:global.btn {
  font-family: Helvetica;
  font-size: 14px;
  color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 100px;
  background-color: transparent;
  padding: 6px 27px;
}

.previewButton:global.btn:focus,
.previewButton:global.btn:active,
.previewButton:global.btn-primary:not(:disabled):not(.disabled):active {
  box-shadow: none;
  background-color: transparent;
  border-color: #fff;
}

.image {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}

.emptyImage {
  display: flex;
  align-items: center;
  justify-content: center;
}

.info {
  padding: 25px 20px 20px 20px;
}

.title {
  font-family: Helvetica;
  font-size: 20px;
  color: #232331;
}

.description {
  font-family: Helvetica;
  font-size: 13px;
  color: #566879;
  line-height: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 56px;
  height: 56px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #f1f8fe;
  padding: 20px;
  box-sizing: border-box;
}

.seeMoreLink {
  font-family: Helvetica;
  font-size: 14px;
  color: #0d70cd;
  text-align: right;
}

.seeMoreLink:hover {
  text-decoration: none;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 76px);
}

.emptyWrapper {
  display: flex;
  width: 100%;
  height: calc(100vh - 76px);
  align-items: center;
  justify-content: center;
}

.empty {
  font-size: 26px;
  color: #232331;
}
